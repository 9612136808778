import { createStore } from 'vuex'
import { contentPath } from '@/helpers'

export default createStore({
  state: {
    modalSource: null,
    modalPassiveDismiss: true,
    modalContext: null,
  },
  mutations: {
    SET_MODAL_SOURCE(state, source) {
      state.modalSource = source ? contentPath(source) : null
    },
    SET_MODAL_PASSIVE_DISMISS(state, dismiss) {
      state.modalPassiveDismiss = dismiss
    },
    SET_MODAL_CONTEXT(state, context) {
      state.modalContext = context
    },
  },
  actions: {
    setModalSource(state, source) {
      state.commit('SET_MODAL_SOURCE', source)
    },
    setModalPassiveDismiss(state, dismiss) {
      state.commit('SET_MODAL_PASSIVE_DISMISS', dismiss)
    },
    setModalContext(state, context) {
      state.commit('SET_MODAL_CONTEXT', context)
    },
    resetModal(state) {
      state.commit('SET_MODAL_SOURCE', null)
      state.commit('SET_MODAL_PASSIVE_DISMISS', true)
      state.commit('SET_MODAL_CONTEXT', null)
    },
  },
  getters: {
    modalSource(state) {
      return state.modalSource
    },
    modalPassiveDismiss(state) {
      return state.modalPassiveDismiss
    },
    modalContext(state) {
      return state.modalContext
    },
  },
})
