<template>
  <div v-if="hasImages" class="slideshow" :style="styles">
    <template v-for="(slide, index) in slides" :key="slide.url">
      <transition :name="transitionName" mode="in-out" appear>
        <img
          v-show="Object.is(index, picked)"
          :style="{ '--y': slide.yShift || '' }"
          :src="slide.url"
          :alt="slide.altText"
        />
      </transition>
    </template>

    <div v-if="showControls" class="slideshow--controls">
      <div class="slideshow--controls--inner button-group is-tight">
        <button is-text  @click="toggle(true)" class="slideshow--cue">
          <Icon :name="playIcon" type="fas" size="2" :fw="false" />
          <svg v-if="timer" viewBox="0 0 100 100" width="100%">
            <circle r="46" cx="50%" cy="50%" class="arc" pathLength="1" />
          </svg>
        </button>

        <template v-for="(slide, index) in slides" :key="slide.url">
          <button
            is-text
            @click="selectImage(index)"
            :class="Object.is(picked, index) ? '' : 'ghost'"
          >
            <Icon name="circle" type="fas" :fw="false" />
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { setInterval, clearInterval } from 'timers'
import {
  computed, defineProps, onMounted, onBeforeUnmount, ref,
} from 'vue'

import Icon from '@/components/Icon.vue'

const props = defineProps({
  slides: {
    type: Array,
    default: () => [],
  },
  duration: {
    type: Number,
    default: 5000,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  controls: {
    type: Boolean,
    default: true,
  },
  start: {
    type: Number,
    default: 1, // not zero-based
  },
  random: {
    type: Boolean,
    default: false,
  },
  transition: {
    type: String,
    default: 'fade',
  },
})

const picked = ref(null)
const timer = ref(null)
const isPlaying = ref(null)
const manuallyStopped = ref(false)
const styles = ref(null)
const hasImages = computed(() => Array.isArray(props.slides) && props.slides?.length > 0)
const hasMultipleImages = computed(() => Array.isArray(props.slides) && props.slides?.length > 1)

const showControls = computed(() => hasMultipleImages.value && props.controls)

const setTransition = (speed) => `${speed}-${props.transition}`
const transitionName = computed(() => (timer.value ? setTransition('slow') : setTransition('medium')))

const startingIndex = computed(() => {
  let index = 0

  if (hasMultipleImages.value) {
    if (props.random && hasMultipleImages.value) {
      index = Math.floor((props.slides.length) * Math.random())
    } else if (props.start > props.slides.length) {
      index = props.slides.length - 1
    } else {
      index = props.start < 1 ? 0 : props.start - 1
    }
  }

  return index
})

const playIcon = computed(() => (timer.value ? 'pause-circle' : 'play-circle'))

const stop = (fakeStop = false) => {
  isPlaying.value = fakeStop
  styles.value = { '--timing': '' }

  if (timer.value) {
    clearInterval(timer.value)
    timer.value = null
  }
}

const respectfulStop = () => stop(isPlaying.value)

const selectImage = (index) => {
  picked.value = index
  stop()
}

const changeImage = () => {
  if (picked.value >= props.slides?.length - 1) {
    picked.value = 0
  } else {
    picked.value += 1
  }
}

const play = (immediate = false) => {
  isPlaying.value = true
  styles.value = {
    '--timing': `${props.duration}ms`,
  }

  if (immediate) {
    changeImage()
  }

  if (!timer.value && hasMultipleImages.value) {
    timer.value = setInterval(changeImage, props.duration);
  }
}

const respectfulPlay = () => {
  if ((isPlaying.value || props.autoplay) && !manuallyStopped.value) {
    play()
  }
}

const toggle = (immediate = false) => {
  if (timer.value) {
    manuallyStopped.value = true
    stop()
  } else {
    manuallyStopped.value = false
    play(immediate)
  }
}

onMounted(() => {
  picked.value = startingIndex.value

  respectfulPlay()
  window.addEventListener('blur', respectfulStop);
  window.addEventListener('focus', respectfulPlay);
})

onBeforeUnmount(() => {
  window.removeEventListener('blur', respectfulStop);
  window.removeEventListener('focus', respectfulPlay);
})
</script>
