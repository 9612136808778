<template>
  <div
    class="site-search"
    :class="{'is-searching': isSearching}"
  >
    <p
      v-if="preSearch"
      class="site-search--helpers button-group"
    >
      <button
        v-for="(term, index) in terms"
        @click="setSearchTerm(term)"
        :key="`${term}-${index}`"
        slim
      >
        {{ term }}
      </button>
    </p>

    <label>
      <Icon
        name="search"
        :fw="false"
      />
      <input
        v-model="search"
        accesskey="s"
        type="search"
        class="site-search--input"
        :autofocus="autofocus"
        :placeholder="placeholderText"
        @keyup.enter="goToSelectedResult"
        @keyup.up="selectPreviousResult"
        @keyup.down="selectNextResult"
      >
    </label>

    <transition name="fade">
      <div
        v-if="isSearching"
        class="site-search--results"
      >
        <slot v-if="preSearch && !hasNoResults" />
        <ul>
          <li
            v-for="(result, index) in filteredResults"
            :key="result.name"
            :class="{ 'is-selected': Object.is(step, index) }"
          >
            <router-link
              :to="{name: result.name}"
              class="no-marker"
              v-html="highlightMatch(result.meta.search.title)"
              @click="clearSearch"
            />
          </li>
          <li
            v-if="hasNoResults"
            class="site-search--results--none"
          >
            <em>Nothing found for <b>{{ search }}</b></em>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import {
  computed, defineProps, onBeforeMount, onMounted, onUnmounted, ref,
} from 'vue'

import { flattenCollection } from '@/helpers'

import Icon from '@/components/Icon.vue'

const router = useRouter()

const props = defineProps({
  minLength: {
    type: [Number, String],
    default: 2,
  },
  mode: {
    type: String,
    default: '',
  },
  placeholderText: {
    type: String,
    default: 'Search...',
  },
  preSearch: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
})
const search = ref('')
const step = ref(0)

const minimumLength = computed(() => parseInt(props.minLength, 10))
const isSearching = computed(() => (
  !!search.value.length
  && search.value.length >= minimumLength.value
))
const flattenedRoutes = computed(() => flattenCollection(router.options.routes))
const filteredResults = computed(() => flattenedRoutes.value
  .filter((route) => route.meta.search && route.meta.search.keywords
    .includes(search.value.toLowerCase())))
const resultCount = computed(() => filteredResults.value.length)
// const hasResults = computed(() => (resultCount.value > 0 && isSearching.value))
const hasNoResults = computed(() => (resultCount.value === 0 && isSearching.value))
const terms = computed(() => props.preSearch.split(' '))

const clearSearch = () => {
  search.value = ''
  step.value = 0
}

const setSearchTerm = (term) => {
  search.value = term
}

const highlightMatch = (text) => {
  const expression = new RegExp(search.value, 'ig')
  return text.replace(expression, '<mark>$&</mark>')
}

const handler = (event) => {
  const classes = ['site-search', 'site-search--input']
  if (classes.filter((className) => !event?.target?.classList?.contains(className))) {
    clearSearch()
  }
}

const goToResult = (index) => {
  if (filteredResults.value[index]) {
    router.push(filteredResults.value[index])
  }

  clearSearch()
}

const goToSelectedResult = () => goToResult(step.value)

const setStep = (increment) => {
  step.value += increment

  if (step.value > resultCount.value - 1) step.value = 0
  if (step.value < 0) step.value = resultCount.value - 1
}

const selectPreviousResult = () => setStep(-1)
const selectNextResult = () => setStep(1)

onBeforeMount(() => {
  if (!props.preSearch) {
    window.addEventListener('click', handler)
  }
})

onMounted(() => {
  if (props.preSearch.length) {
    const term = terms.value[0]
    search.value = term // props.preSearch
  }
})

onUnmounted(() => {
  if (!props.preSearch) {
    window.removeEventListener('click', handler)
  }
})
</script>
