<template>
  <Component :is="tag" class="flyout" :class="classes">
    <button
      is-text
      :class="[
        'flyout--trigger',
        name && `flyout--trigger--${name}`
      ]"
      @click="toggleFlyout"
    >
      <Icon v-if="icon" :name="icon.name" :type="icon.type" :size="icon.size" :fw="icon.fw" />
      <span>{{ label }}</span>
      <Icon name="angle-down" type="fal" class="flyout--caret" :fw="false" />
    </button>
    <transition name="fade">
      <div class="flyout--box" v-show="flyoutShouldBeOpen">
        <slot />
      </div>
    </transition>
  </Component>
</template>

<script setup>
import {
  computed, defineProps, ref, onBeforeMount, onUnmounted,
} from 'vue'

import { joinClasses } from '@/helpers'

import Icon from '@/components/Icon.vue'

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  label: {
    type: String,
    required: true,
  },
  icon: {
    type: Object,
    default: null,
  },
  name: {
    type: String,
    default: '',
  },
  anchor: {
    type: String,
    default: 'left',
  },
})

const flyoutIsOpen = ref(false)
const flyoutShouldBeOpen = computed(() => flyoutIsOpen.value)

const classes = computed(() => joinClasses([
  `is-anchored--${props.anchor}`,
  props.name,
  flyoutShouldBeOpen.value && 'is-open',
]))

const handler = (event) => {
  if (!event?.target?.classList?.contains(`flyout--trigger--${props.name}`)) {
    flyoutIsOpen.value = false
  }
}

const toggleFlyout = () => {
  flyoutIsOpen.value = !flyoutIsOpen.value
}

onBeforeMount(() => {
  window.addEventListener('click', handler)
})

onUnmounted(() => {
  window.removeEventListener('click', handler)
})
</script>
