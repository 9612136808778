<template>
  <section class="calendar">
    <header>
      <h3><b>{{ monthName }}</b> {{ year }}</h3>
      <div v-if="controls" class="button-group">
        <button simple @click="goToPreviousMonth">
          <Icon name="arrow-circle-left" />
        </button>
        <button simple @click="goToToday">
          <Icon name="dot-circle" />
        </button>
        <button simple @click="goToNextMonth">
          <Icon name="arrow-circle-right" />
        </button>
      </div>
    </header>

    <div class="calendar--inner">
      <div class="calendar--body">
        <div class="days-of-week">
          <div v-for="day in DAYS_OF_WEEK" :key="day">{{ day }}</div>
        </div>

        <div class="days-of-month">
          <div v-for="day in before" :key="day" class="bump">
            {{ day }}
          </div>
          <div v-for="day in daysInMonth" :key="`${year}${month}${day}`" :class="dayClasses(day)">
            {{ day }}
          </div>
          <div v-for="day in after" :key="day" class="bump">
            {{ day }}
          </div>
        </div>
      </div>

      <div v-if="items?.length" class="calendar--list">
        <ul>
          <template
            v-for="(item, index) in items"
            :key="`${item.date}-${index}`"
          >
            <CalendarItem
              :item="item"
              v-if="item.type !== 'buffer'"
            />
          </template>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
import dayjs from 'dayjs'
import { computed, defineProps, ref } from 'vue'

import { removeDuplicates } from '@/helpers'

import Icon from '@/components/Icon.vue'
import CalendarItem from '@/components/bits/CalendarItem.vue'

const props = defineProps({
  seed: {
    type: String,
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  controls: {
    type: Boolean,
    default: true,
  },
})

const TODAY = dayjs().startOf('day')
const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const now = ref(dayjs(props.seed))
const start = computed(() => now.value.date(1).day())
const daysInMonth = computed(() => now.value.daysInMonth())
const before = computed(() => {
  const days = []
  const n = now.value.subtract(1, 'month').daysInMonth()

  for (let i = 0; i < start.value; i += 1) {
    days.push(n - i)
  }

  return days.reverse()
})
const after = computed(() => 6 - now.value.endOf('month').day())
const year = computed(() => now.value.format('YYYY'))
const month = computed(() => now.value.format('MM'))
const monthName = computed(() => now.value.format('MMMM'))

const dayIsToday = (date) => (dayjs(date).isSame(TODAY, 'day')
  ? 'is-today'
  : '')

const dayIsBeforeToday = (date) => (dayjs(date).isBefore(TODAY, 'day')
  ? 'is-past'
  : '')

const dayIsWeekend = (date) => ([0, 6].includes(dayjs(date).day())
  ? 'is-weekend'
  : '')

const dayHasEvent = (date) => {
  const types = []
  const found = props.items.filter((item) => (
    !item.type !== 'buffer'
    && (
      item?.dates?.includes(date)
      || (item.date === date && !item.tbd)
    )
  )).map((item) => item.type && types.push(`is-${item.type}`))

  const uniqueTypes = removeDuplicates(types)

  if (uniqueTypes.length > 1) {
    uniqueTypes.push(`has-${uniqueTypes.length}-types`)
  }

  return found
    ? uniqueTypes.join(' ') // `is-${found.type}`
    : ''
}

const constructedDate = (day) => `${year.value}-${month.value}-${day.toString().padStart(2, '0')}`

const goToPreviousMonth = () => {
  now.value = now.value.subtract(1, 'month')
}

const goToToday = () => {
  now.value = TODAY
}

const goToNextMonth = () => {
  now.value = now.value.add(1, 'month')
}

const dayClasses = (day) => {
  const date = constructedDate(day)

  return [
    dayIsToday(date),
    dayIsBeforeToday(date),
    dayHasEvent(date),
    dayIsWeekend(date),
  ]
}
</script>
