const Plugin = require('markdown-it-regexp');

// eslint-disable-next-line camelcase
module.exports = function time_format_plugin(md) {
  const replacer = (match) => match
    .replace(/am/gi, '&nbsp;<sub>AM</sub>')
    .replace(/pm/gi, '&nbsp;<sub>PM</sub>')

  md.use(Plugin(
    /:time ([0-9:\w]+)\s*([0-9:\w]*):/,
    (match, utils) => {
      const t1 = replacer(utils.escape(match[1]))
      const t2 = match[2] ? `&nbsp;–&nbsp;${replacer(utils.escape(match[2]))}` : ''

      return `<span class="time">${t1}${t2}</span>`
    },
  ));
};
