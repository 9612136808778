const Plugin = require('markdown-it-regexp');

// eslint-disable-next-line camelcase
module.exports = function fontawesome_plugin(md) {
  // FA5 style.
  md.use(Plugin(
    // /:fa([\w])-([\w-]+):/,
    /:fa(\w)-([\w-]+)\s*([\w- ]*):/,
    (match, utils) => `<span class="md-icon fa${utils.escape(match[1])} fa-${utils.escape(match[2])} ${utils.escape(match[3])} fa-fw"></span>`,
  ));
};
