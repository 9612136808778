<template>
  <div v-if="showHero" class="hero" :style="headerStyles">
    <SlideShow
      :slides="slides"
      :duration="duration"
      :start="start"
      :autoplay="autoplay"
      :controls="controls"
      :random="random"
      transition="fade"
    />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import SlideShow from '@/components/SlideShow.vue'

const route = useRoute()

const slideshow = computed(() => route.meta?.header?.slideshow)
const hasHeaderImage = computed(() => !!route.meta?.header?.image)

const duration = computed(() => slideshow.value?.duration)
const start = computed(() => slideshow.value?.start)
const autoplay = computed(() => slideshow.value?.autoplay)
const controls = computed(() => slideshow.value?.controls)
const random = computed(() => slideshow.value?.random)
const slides = computed(() => slideshow.value?.slides)

const hasSlideShow = computed(() => Array.isArray(slides.value) && slides.value?.length > 0)
const showHero = computed(() => hasHeaderImage.value || hasSlideShow.value)

const headerStyles = computed(() => {
  const { image, position, blendMode } = route.meta.header

  return hasHeaderImage.value && !hasSlideShow.value && {
    '--header-bg-img': `url(${image})`,
    '--header-bg-pos': position || '',
    '--header-blend-mode': blendMode || '',
  }
})
</script>
