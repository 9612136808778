const Plugin = require('markdown-it-regexp');

// eslint-disable-next-line camelcase
module.exports = function line_break_plugin(md) {
  // md.use(Plugin(
  //   /\s*(...)\s*/gi,
  //   (match, utils) => `<small class="md-small">${utils.escape(match[1])}</small>`,
  // ))

  md.use(Plugin(
    /\s*(::)\s*/g,
    () => '<br class="md-linebreak"/>',
  ))

  // md.use(Plugin(
  //   / \^{2} /,
  //   () => '<br />',
  // ))
}
