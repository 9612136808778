const HELPERS = require('@/helpers')

// see this regex: https://regex101.com/r/AyYTpG/1
const BUTTON_OPEN_REGEX = /{\s*jotform\s*:\s*(\w+)\s*(\.+\S+)*\s*(@\S+)*\s*(#+\S+)*\s*}/
const BUTTON_CLOSE_REGEX = /{\s*jotform\s*}/
const TOKEN_TYPE = 'jotform'
const MARKUP = '{jotform}'

function getAttribute(attrs, attr) {
  if (!attrs || attrs.length < 1) { return null }

  for (let ii = 0; ii < attrs.length; ii += 1) {
    const att = attrs[ii]
    if (att && att.length > 1) {
      if (att[0] === attr) {
        return att[1]
      }
    }
  }

  return null
}

function action(state, silent) {
  let result
  let nesting = 1
  // let isOpen = true
  const max = state.posMax
  const start = state.pos

  if (state.src.charCodeAt(start) !== 0x7B/* ~ */) { return false }
  if (silent) { return false } // don't run any pairs in validation mode

  const content = state.src.slice(start)
  const startResult = BUTTON_OPEN_REGEX.exec(content)
  const endResult = BUTTON_CLOSE_REGEX.exec(content)

  if (!startResult && !endResult) { return false }

  if (startResult && endResult) {
    if (startResult.index < endResult.index) {
      result = startResult
    } else {
      nesting = -1
      result = endResult
    }
  } else if (startResult) {
    result = startResult
  } else {
    result = endResult
    nesting = -1
  }

  // isOpen = result.length >= 2
  state.posMax = start + result[0].length

  const token = state.push(TOKEN_TYPE, 'button', nesting)
  token.markup = MARKUP

  if (result.length >= 2) {
    token.attrPush(['form-id', result[1]])

    if (result[2]) {
      token.attrPush(['class', result[2].replaceAll('.', ' ').trim()])
    }

    if (result[3]) {
      token.attrPush(['attrs', result[3].replaceAll('@', ' ').trim()])
    }

    if (result[4]) {
      token.attrPush(['dom-id', result[4].replaceAll('#', '').trim()])
    }
  }

  state.pos = state.posMax
  state.posMax = max
  return true
}

// eslint-disable-next-line no-unused-vars
function renderDefault(tokens, idx, _options, env, self) {
  const token = tokens[idx]
  const result = ['<']

  if (token.nesting === -1) {
    result.push('/')
  }

  result.push('button')

  if (token.nesting === 1) {
    const formId = getAttribute(token.attrs, 'form-id')
    const domId = getAttribute(token.attrs, 'dom-id')
    const classname = getAttribute(token.attrs, 'class')
    const attrs = getAttribute(token.attrs, 'attrs')

    if (formId) {
      result.push(` form-cue onclick="${HELPERS.formAction(formId)}"`)
    }

    if (domId) {
      result.push(` id="${domId}"`)
    }

    if (classname) {
      result.push(` class="${classname}"`)
    }

    if (attrs) {
      result.push(attrs)
    }
  }

  result.push('>')

  return result.join('')
}

// eslint-disable-next-line camelcase
module.exports = function button_plugin(md, name, options = {}) {
  md.inline.ruler.after('emphasis', 'span', action)
  md.renderer.rules[TOKEN_TYPE] = options.render || renderDefault
}
