<template>
  <LayoutContent />

  <section>
    <Staff :data="staffData" />
  </section>
</template>

<script setup>
import axios from 'axios'
import { useRoute } from 'vue-router'
import { computed, ref, onMounted } from 'vue'

import LayoutContent from '@/layouts/LayoutContent.vue'
import Staff from '@/components/Staff.vue'

const route = useRoute()
const staffData = ref([])
const staffSource = computed(() => route.meta.staffSource)

onMounted(async () => {
  axios.get(staffSource.value)
    .then((response) => {
      staffData.value = response?.data
    })
    .catch((error) => {
      if (error.response?.status === 404) {
        staffData.value = `Not Found: ${staffSource.value}`
      } else {
        staffData.value = `Error: ${error.response?.status}: ${error.response?.statusText}`
      }
    })

  if (!staffData.value) {
    staffData.value = `Not Found: ${route.meta.staffSource}`
  }
})
</script>
