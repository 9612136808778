<template>
  <Markdown
    :tag="tag"
    :html="true"
    :breaks="true"
    :source="content"
    :class="[item.tbd && 'is-tbd', `is-${item.type}`]"
  />
</template>

<script setup>
import { computed, defineProps } from 'vue'
import Markdown from '@/modules/markdown'

const props = defineProps({
  tag: {
    type: String,
    default: 'li',
  },
  item: {
    type: Object,
    default: () => {},
  },
})

const content = computed(() => {
  const day = `**${props.item.tbd ? 'TBD' : props.item.day}** `
  const note = props.item.note ? '' : day

  return `:${props.item.icon || 'fas-square'}: <span>${note}${props.item.description}</span>`
})
</script>
