<template>
  <router-view v-slot="{ Component }">
    <Header />

    <transition name="slide">
      <Menu menu="subnav" class="app-subnav" prefix="subnav" :local="true" />
    </transition>

    <transition name="fade" mode="out-in">
      <component :is="Component" :key="$route?.path"></component>
    </transition>

    <Footer />
  </router-view>
</template>

<script setup>
import { onMounted } from 'vue'
import store from '@/store'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Menu from '@/components/Menu.vue'

onMounted(() => {
  window.setModalSource = (source, allowPassiveDismiss) => {
    store.dispatch('setModalSource', source)
    store.dispatch('setModalPassiveDismiss', allowPassiveDismiss)
  }
})
</script>
