<template>
  <nav
    v-if="shouldShowCrumbs"
    class="crumbs"
  >
    <li class="crumbs--item">
      <router-link to="/">Home</router-link>
    </li>

    <Crumb
      v-for="(crumb, index) in crumbs"
      :key="index"
      :item="crumb"
    />
  </nav>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import Crumb from './Crumb.vue'

const props = defineProps({
  crumbs: {
    type: Array,
    default: () => [],
  },
})

const shouldShowCrumbs = computed(() => (
  props.crumbs.length >= 1 && !Object.is(props.crumbs[0].path, '/')
))
</script>
