import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CONSTANTS from './constants'
import * as HELPERS from './helpers'

import './styles/app.scss'

router.beforeEach(() => {
  store.dispatch('resetModal')
})

router.afterEach((to) => {
  document.title = to?.meta?.title ? `${to.meta.title} • JO/TTT` : 'JO/TTT'
})

const app = createApp(App)

app.config.globalProperties.$CONSTANTS = CONSTANTS
app.config.globalProperties.$HELPERS = HELPERS

app.use(store)
  .use(router)
  .mount('#app')
