<template>
  <li
    v-if="shouldShowItem"
    class="crumbs--item"
  >
    <router-link :to="{path: item.path}" class="no-marker">
      {{ item.meta.crumb }}
    </router-link>
  </li>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
})

const shouldShowItem = computed(() => !!(props.item?.meta?.crumb))
</script>
