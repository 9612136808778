<template>
  <section
    v-for="item in activeItems"
    :key="item.id"
    class="staff--member content-block"
  >
    <div class="staff--name">
      <h3
        v-if="item.displayName"
        :id="item.id"
        tabindex="-1"
      >
        {{ item.displayName }}
        <small v-if="item.title"><br>{{ item.title }}</small>
      </h3>
    </div>

    <div class="staff--image">
      <img
        v-if="item.image"
        :src="$HELPERS.mediaUrl(item.image)"
        :alt="item.name"
        kind="staff"
        @error="handleNotFoundError($event, item.org)"
      />
    </div>

    <Markdown
      tag="div"
      class="staff--bio"
      :html="false"
      :breaks="true"
      :source="item.bio"
    />
  </section>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { imageNotFound } from '@/helpers'

import Markdown from '@/modules/markdown'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({ items: [] }),
  },
})

const activeItems = computed(() => props.data?.items?.filter((item) => item.active))

const handleNotFoundError = (event, variant) => imageNotFound(event, variant)
</script>
