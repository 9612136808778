<template>
  <LayoutContent />
  <SiteSearch :pre-search="possibleTerms" :autofocus="true">
    <h4>Try these pages?</h4>
  </SiteSearch>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'

import CONSTANTS from '@/constants'

import LayoutContent from '@/layouts/LayoutContent.vue'
import SiteSearch from '@/components/SiteSearch.vue'

const route = useRoute()

const parts = computed(() => route?.path.split('/'))
const cleanedParts = computed(() => ([...parts.value]
  .pop()
  .replace('.html', '')
  .replaceAll('%20', ' ')
  .replaceAll('-', ' ')
  .split(' ')))

const possibleTerms = computed(() => cleanedParts.value
  .filter((part) => !CONSTANTS.IGNORABLES.includes(part))
  .join(' '))
</script>
