<template>
  <div
    v-if="isOpen"
    :class="modalClasses"
    @click="dismissModal(allowPassiveDismiss)"
  >
    <article class="modal--inner" @click.stop>
      <MarkdownDisplay :file="source" class="modal--body" :key="source" />

      <footer class="modal--footer">
        <button @click="dismissModal(true)" outlined slim>
          Close
        </button>
      </footer>
    </article>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import store from '@/store'
import MarkdownDisplay from '@/components/MarkdownDisplay.vue'

const source = computed(() => store.state?.modalSource)
const context = computed(() => store.state?.modalContext)
const allowPassiveDismiss = computed(() => store.state?.modalPassiveDismiss)
const isOpen = computed(() => source.value)

const modalClasses = computed(() => ([
  'modal',
  context.value,
]))

const dismissModal = (allow) => {
  if (allow) {
    store.dispatch('resetModal')
  }
}
</script>
