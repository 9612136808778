<template>
  <LayoutContent />

  <Calendar v-for="month in monthsInData"
    :key="month"
    :controls="false"
    :seed="month"
    :items="entriesInMonth(calendarData, month)"
  />
</template>

<script setup>
import axios from 'axios'
import dayjs from 'dayjs'
import { useRoute } from 'vue-router'
import { computed, ref, onMounted } from 'vue'

import CONSTANTS from '@/constants'
import { entriesInMonth, formatDay } from '@/helpers'

import LayoutContent from '@/layouts/LayoutContent.vue'
import Calendar from '@/components/Calendar.vue'

const route = useRoute()
const calendarData = ref([])

const calendarSource = computed(() => route.meta.calendarSource)
const monthsInData = computed(() => {
  const data = route.query.show === 'all'
    ? calendarData.value
    : calendarData.value.filter((item) => dayjs(item.month) >= dayjs().startOf('month'))

  return [...new Set(data.map((item) => item.month))]
})

onMounted(async () => {
  axios.get(calendarSource.value)
    .then((response) => {
      const data = response?.data

      calendarData.value = data.map((entry) => ({
        ...entry,
        month: dayjs(entry.date).startOf('month').format(CONSTANTS.DATE_FORMATS.FULL),
        day: formatDay(entry),
      })).sort((a, b) => (new Date(a.date) - new Date(b.date)))
    })
    .catch((error) => {
      if (error.response?.status === 404) {
        calendarData.value = `Not Found: ${calendarSource.value}`
      } else {
        calendarData.value = `Error: ${error.response?.status}: ${error.response?.statusText}`
      }
    })

  if (!calendarData.value) {
    calendarData.value = `Not Found: ${route.meta.calendarSource}`
  }
})
</script>
