import Markdown from './markdown'

const install = (Vue) => {
  Vue.component(Markdown.name, Markdown)
};

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

Markdown.install = install

export default Markdown
