<template>
  <article v-if="showAnnouncements" class="app-announcements">
    <MarkdownDisplay :file="content" />
  </article>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { contentPath } from '@/helpers'
import MarkdownDisplay from '@/components/MarkdownDisplay.vue'

const route = useRoute()
const content = computed(() => contentPath('announcements.md'))
const showAnnouncements = computed(() => ['home'].includes(route.name))
</script>
