<template>
  <header class="app-header" :class="{'main-menu--is-open': mainMenuShouldBeOpen}">
    <div class="app-header--inner">
      <div class="main-menu">
        <button class="main-menu--button" @click="toggleMainMenu">
          <Icon name="bars" size="lg" :fw="false" />
          <span class="menu-item--label">Menu</span>
        </button>

        <Menu menu="main" prefix="main-menu" :deep="true" @click="closeMainMenu">
          <template #before>
            <li class="main-menu--home-alt">
              <router-link to="/">
                <Icon name="home" size="lg" :fw="false" />
                <span class="menu-item--label">JO/TTT</span>
              </router-link>
            </li>
          </template>

          <template #after>
            <Flyout tag="li" label="Activities &amp; Camps" name="activity-flyout">
              <Menu menu="activity" prefix="activity-menu" :deep="true" />
            </Flyout>
          </template>
        </Menu>
      </div>

      <Flyout label="For Parents" :icon="parentsIcon" name="parent-menu">
        <Menu menu="parents" prefix="parent-menu" :deep="true">
          <template #before>
            <li>
              <a :href="$HELPERS.portalUrl()" target="_blank" class="is-plain">Parent Portal</a>
            </li>
          </template>
        </Menu>
      </Flyout>

      <SiteSearch />
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

import Flyout from '@/components/Flyout.vue'
import Icon from '@/components/Icon.vue'
import SiteSearch from '@/components/SiteSearch.vue'
import Menu from '@/components/Menu.vue'

const mainMenuShouldBeOpen = ref(false)

const parentsIcon = ref({
  name: 'user-circle',
  type: 'fas',
  size: 'lg',
  fw: false,
})

const closeMainMenu = () => {
  mainMenuShouldBeOpen.value = false
}

const toggleMainMenu = () => {
  mainMenuShouldBeOpen.value = !mainMenuShouldBeOpen.value
}

const handler = (event) => {
  if (!event?.target?.classList?.contains('main-menu--button')) {
    closeMainMenu()
  }
}

onMounted(() => window.addEventListener('click', handler))
onUnmounted(() => window.removeEventListener('click', handler))
</script>
