<template>
  <footer class="app-footer">
    <section>
      <Menu menu="footer" :deep="true">
        <template #after>
          <li>
            <a :href="$HELPERS.portalUrl()" target="_blank">Parent Portal</a>
          </li>
        </template>
      </Menu>
    </section>

    <section>
      <h4>Follow Us</h4>
      <p>
        <a href="https://www.facebook.com/JuniorOlympicGymnastics/" target="_blank" class="button-ish no-marker">
          <Icon name="facebook" type="fab" size="lg" />
          <b>JuniorOlympicGymnastics</b>
        </a>

        <a href="https://www.instagram.com/juniorolympic_gymnastics/" target="_blank" class="button-ish no-marker">
          <Icon name="instagram" type="fab" size="lg" />
          <b>@juniorolympic_gymnastics</b>
        </a>
      </p>
    </section>

    <MarkdownDisplay :file="testimonials" />

    <section>
      <p>Copyright &copy; {{ year }} Tiny Tots &amp; Tumblers, LLC. All Rights Reserved.</p>
    </section>
  </footer>
</template>

<script setup>
import { computed } from 'vue'

import { contentPath } from '@/helpers'

import Icon from '@/components/Icon.vue'
import MarkdownDisplay from '@/components/MarkdownDisplay.vue'
import Menu from '@/components/Menu.vue'

const year = computed(() => new Date().getFullYear())
const testimonials = computed(() => contentPath('_fragments/_footer-testimonials.md'))
</script>
