<template>
  <main class="app-body" :class="pageClass">
    <Hero />

    <Announcements />

    <article v-cloak>
      <div class="app-logos">
        <a href="/gymnastics-programs">
          <img :src="$HELPERS.mediaUrl('/logos/JO@0.25x.png')" class="logo--jo" />
        </a>
        <a href="/academic-preschool">
          <img :src="$HELPERS.mediaUrl('/logos/TTT@0.25x.png')" class="logo--ttt" />
        </a>
      </div>

      <Crumbs :crumbs="crumbs" />

      <router-view />
    </article>

    <transition name="fade">
      <div class="end-of-page-tools" v-if="showPageTools">
        <button class="go-to-top" @click="goToTop">
          <small><Icon name="arrow-circle-up" type="fas" size="lg" /> Top</small>
        </button>
      </div>
    </transition>

    <transition name="modal">
      <Modal />
    </transition>
  </main>
</template>

<script setup>
import { throttle } from 'lodash'
import { setTimeout } from 'timers'
import { useRoute } from 'vue-router'
import {
  computed, ref, onBeforeMount, onMounted, onUnmounted,
} from 'vue'

import Announcements from '@/components/Announcements.vue'
import Crumbs from '@/components/Crumbs.vue'
import Hero from '@/components/Hero.vue'
import Icon from '@/components/Icon.vue'
import Modal from '@/components/Modal.vue'

const route = useRoute()

const showTopLink = ref(false)
const pageClass = computed(() => route.meta.classname)
const crumbs = computed(() => route.matched.filter((entry) => !!entry.meta.crumb))
const anchors = computed(() => document.querySelectorAll('article a[href^="#"]'))
const showPageTools = computed(() => showTopLink.value)

const interceptAnchor = (event, anchor) => {
  event.preventDefault()
  event.stopPropagation()
  const id = anchor.hash.replace('#', '')
  const element = document.getElementById(id)
  element.scrollIntoView()

  return false
}

const handleScroll = () => {
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
  const isScrolling = scrollTop > 72
  showTopLink.value = scrollTop > 220

  if (isScrolling && ![...document.body.classList].includes('did-scroll')) {
    document.body.classList.add('did-scroll')
  }

  if (!isScrolling && [...document.body.classList].includes('did-scroll')) {
    document.body.classList.remove('did-scroll')
  }
}

const goToTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

onBeforeMount(() => window.addEventListener('scroll', throttle(handleScroll, 200)))

onUnmounted(() => window.removeEventListener('scroll', throttle(handleScroll, 200)))

onMounted(() => setTimeout(() => {
  anchors.value
    .forEach((anchor) => anchor
      .addEventListener('click', (event) => interceptAnchor(event, anchor)))
}, 200))
</script>
