<template>
  <MarkdownDisplay :file="content" />

  <template v-if="content2">
    <MarkdownDisplay :file="content2" />
  </template>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'

import MarkdownDisplay from '@/components/MarkdownDisplay.vue'

const route = useRoute()
const content = computed(() => route.meta.content)
const content2 = computed(() => route.meta.content2)
</script>
