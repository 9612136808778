<template>
  <nav v-if="menuItems?.length">
    <ul>
      <slot name="before" />

      <li v-for="item in menuItems" :key="item.path" :class="classname(item)">
        <router-link :to="item" class="no-marker">
          {{ itemTitle(item) }}
        </router-link>
      </li>

      <slot name="after" />
    </ul>
  </nav>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { computed, defineProps } from 'vue'

import { branches } from '@/helpers'

const route = useRoute()
const router = useRouter()
const props = defineProps({
  menu: {
    type: String,
    default: null,
  },
  local: {
    type: Boolean,
    default: false,
  },
  deep: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: null,
  },
})

const tree = computed(() => router.options.routes?.filter((item) => (
  props.local
    ? !item?.redirect && item.path !== '/'
    : !item?.redirect
)))

const menuItems = computed(() => {
  const scope = props.local
    ? tree.value.find((entry) => route.path.includes(entry.path))?.children
    : tree.value

  return branches(scope, props.menu, props.deep)
})

const itemTitle = (item) => item.meta.menu[props.menu].title || item.meta.title

const classname = (item) => [
  item.meta?.classname && `${props.prefix}--${item.meta?.classname}`,
  item.meta?.menu[props.menu]?.hideMobile && 'is-mobile-hide',
  item.meta?.menu[props.menu]?.star && 'show-star',
]
</script>
