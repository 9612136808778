<template>
  <component :is="tag" :class="classes" v-html="content" />
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { joinClasses } from '@/helpers';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'far',
  },
  size: {
    type: [String, Number],
    default: null,
  },
  fw: {
    type: Boolean,
    default: true,
  },
})

const realType = computed(() => props.type || 'fa')
const isSvg = computed(() => realType.value === 'svg')
const tag = computed(() => (isSvg.value ? 'svg' : 'span'))

const classes = computed(() => joinClasses([
  'icon',
  isSvg.value
    ? `icon--svg icon--${props.name}`
    : `${realType.value} fa-${props.name}`,
  props.fw && 'fa-fw',
  props.size && (parseInt(props.size, 10) > 1
    ? `fa-${props.size}x`
    : `fa-${props.size}`),
]))

const content = computed(() => (
  isSvg.value
    ? `<use xlink:href="#svg-${props.name}" />`
    : null
))
</script>
